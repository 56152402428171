import { Col, Form, Row, Button, Input, message, Table, Modal } from "antd";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import axiosInstance from "../../../axiosInstance";
import { UserContext } from "../../../globalContext";

const UpiScreen = () => {
  const user =useContext(UserContext)
  const [name, setName] = useState("");
  
  const [roleData, setRoleData] = useState([]);
  const [readRoleObj, setReadRoleObj] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (mounted) getRole();
    return () => (mounted = false);
  }, []);
  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    // },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",
      key: "id",
      render: (row) => {
        return (
          <>
            <Button
            onClick={() => {
              onPutUpi(row);
            }}>Active</Button>
            <Button
              style={{ color: "red", marginLeft: 12 }}
              onClick={() => {
                removeRole(row);
              }}
            >Delete</Button>
          </>
        );
      },
    },
  ];

  const getRole = () => {
    axiosInstance.get(`/upi`).then((response) => {
      console.log("upi data ", response.data.data)
      setRoleData(response.data.data);
    });
  };
  const putUpi = (e) => {
    axiosInstance.put(`/upi/${e}` , {status:"activate"}).then((response) => {
      console.log("upi data ", response.data.data)
      getRole();
    });
  };
  const onPutUpi = (obj) => {
    console.log("obj",obj)
    Modal.confirm({
      title: "Do you want to active this upiId?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axiosInstance.post(`/upi/active`).then((res) => {
          console.log("res data",res.data.data.length)
          if(res.data.data.length === 0){
            console.log("me call ho gya")
              putUpi(obj._id);
          }
          else{
            axiosInstance.put(`/upi/${res.data.data[0]._id}` , {status:"deactivate"}).then((response) => {
              putUpi(obj._id);
          });
            }
        });
      },
      onCancel() {},
    });
    // axiosInstance.put(`/upi/${obj._id}` , {status:"activate"}).then((response) => {
    //   console.log("upi data ", response.data.data)
    //   setRoleData(response.data.data);
   // });
  };


  const onSave = () => {
    const data = {
      name: name,
      status:"deactivate"
    };
    axiosInstance.post(`/upi`, data).then((res) => {
      if (res.data && res.data.responseCode === -1) {
        message.error("Record Already Exists");
      } else if (res.data && res.data.responseCode === 1) {
        message.success("Record saved successfully");
        getRole();
      } else message.error("Something wrong. Please try again...!");
    });
  };

  const removeRole = (obj) => {
    Modal.confirm({
      title: "Do you want to remove this Member?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        axiosInstance.delete(`/upi/${obj.id}`).then((res) => {
          if (res.data && res.data.responseCode === 1) {
            message.success("Record Deleted successfully");
            getRole();
          } else message.error("Something wrong. Please try again...!");
        });
      },
      onCancel() {},
    });
  };

  return (
   <div>
       <div>
      <div className="text-center main-heading">
      {/* <PageTitle title="Role Screen">
        </PageTitle> */}
        <h1>Upi Screen</h1>
        </div>
      </div>
     <div className="master-role-screen container">
   
     
      <div>
        <Form name="basic">
        <div className="row mb-3 end d-flex align-items-center">
            <div className="col-md-4 text">
              <label >Name</label>
            <Form.Item colon={false} className="raj0101">
              <Input
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Item>
          </div>
          <div className="col-md-4 text ">
            <Button  onClick={onSave} className="btn">
              Save
            </Button>
          </div>
        </div>
        </Form>
      </div>
      <div>
        <Table columns={columns} dataSource={roleData} />
      </div>
    </div>
   </div>
  );
};

export default UpiScreen;
