import React, { useState, useContext, useEffect } from "react";
import { Col, Form, Row, Input, Button, message, Modal, Image } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import axiosInstance from "../axiosInstance";
import { UserContext } from "../globalContext";
import logo from "../../src/images/Orbotlogop.png";
import { useNavigate } from "react-router-dom";
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBCheckbox
}
from 'mdb-react-ui-kit';
// import React, {useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import {Layout, Menu, Dropdown} from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
// import {environment} from './Environment';
import iconUser from '../../src/images/Icon-user.svg';
// import logo from '../src/images/logo.jpg';
import Icon from '@ant-design/icons';
import img from '../../src/images/Orbotlogop.png';
import Hamburger from 'hamburger-react';
// import {useState} from 'react';
import manue from '../static/media/menu.png'
import imglogo from "../static/media/Realludokinglogo.png"
import wollet from "../static/media/wallet.png"
import offer from "../../src/images/offe.jpg"
import bg from "../static/media/Realludokinglogo.png"

import ForgotPasswordModel from "./ForgotPassword.model";
import { Container } from "react-bootstrap";
import { Nav2 } from "../Nav2";

const LoginScreen = () => {
  let navigate = useNavigate();
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isModalOpen1, setIsModalOpen1] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const user = useContext(UserContext);
  useEffect(() => {

    let mounted = true;
    if (mounted) {

      
      if (user.userId !== null) {
        // navigate("/HomeScreen", { replace: true });
        localStorage.clear();
        window.location.reload();
      }


    }
    return () => (mounted = false);
  }, []);

  const onCancel = () => {
    setIsModalOpen(false);
  };
  const onAdd = () => {
    setIsModalOpen(false);
  };

  const onSignin = () => {
  

    axiosInstance
      .post("/masterLogin", { mobile: mobile, password: password })
      .then((res) => {
       
        if (res.data) {
          if(res.data.responseCode === 1){
           
            localStorage.setItem("authtoken", JSON.stringify(res.data.auth));
 navigate(`/HomeScreen`, { replace: true });
           // window.location.href = "#/HomeScreen";
  
          }
          if(res.data.responseCode === -2){
            message.error("Your account is temporarily frozen");
          }
          if(res.data.responseCode === -1){
            message.error("please enter correct mobile number or password");
          }

        } else message.error("please enter correct mobile number or password");
      })
      .catch((err) => {
        message.error("please enter correct mobile number or password");
      });
  };
  const onSignup = () => {
    navigate('/SignupScreen', { replace: true });
    // window.location.href= `/SignupScreen`;
  };

  const onForgotPassword = () => {
    setIsModalOpen(true);
    // navigate('/SSignupScreen', { replace: true });

  };


  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const handleToggleClick = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  

  return (
  <div className="" style={{marginTop:"25px"}}>
   
 <MDBContainer fluid className="p-3 my-5">

<MDBRow>

  <MDBCol col='10' md='6'>
    <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg" class="img-fluid" alt="Phone image" />
  </MDBCol>

  <MDBCol col='4' md='6'>


    <MDBInput wrapperClass='mb-4' label='Mobile Number' onChange={(e) => setMobile(e.target.value)} id='formControlLg' type='number' size="lg"/>
    <MDBInput wrapperClass='mb-4' label='Password' id='formControlLg' onChange={(e) => setPassword(e.target.value)} type='password' size="lg"/>


    <div className="d-flex justify-content-between mx-4 mb-4">
      {/* <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' label='Remember me' /> */}
      {/* <a >Forgot password?</a> */}
    </div>

    <MDBBtn onClick={() =>onSignin()} className="mb-4 w-100" style={{height:"50px"}} size="lg">Sign in</MDBBtn>

    {/* <div className="divider d-flex align-items-center my-4">
      <p className="text-center fw-bold mx-3 mb-0">OR</p>
    </div> */}

    {/* <MDBBtn className="mb-4 w-100" size="lg" style={{backgroundColor: '#3b5998'}}>
      <MDBIcon fab icon="facebook-f" className="mx-2"/>
      Continue with facebook
    </MDBBtn>

    <MDBBtn className="mb-4 w-100" size="lg" style={{backgroundColor: '#55acee'}}>
      <MDBIcon fab icon="twitter" className="mx-2"/>
      Continue with twitter
    </MDBBtn> */}

  </MDBCol>

</MDBRow>

</MDBContainer>
  </div>

  );
};

export default LoginScreen;
