import React, { useContext, useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { PageTitle } from "../PageTitle";
import { UserContext } from "../globalContext";
import { useNavigate } from "react-router-dom";
import { Footer } from "antd/lib/layout/layout";
import { Footers } from "../Footers";
import ludo from "../static/media/new-board.png";
import { Button } from "react-bootstrap";
import call from "../static/media/live-chat.png";
import spin from "../static/media/roulette.png";
import hello from "../static/media/4.png"
import bg from "../static/media/Realludokinglogo.png"
import axiosInstance from "../axiosInstance";



const HomeScreen = (props) => {


    const user = useContext(UserContext);
    let navigate = useNavigate();
    const today = new Date();
    const [todayUser, setTodayUser] = useState();
    const [todayRecharge, setTodayrecharge] = useState();
    const [todayWithdraw, setTodayWithdraw] = useState();
    const [todayProfit, setTodayProfit] = useState();
    const [todayCGame, setTodayCGame] = useState();
    const [todaySGame, setTodaySGame] = useState();
    const [todayCGame2, setTodayCGame2] = useState();
    const [todaySGame2, setTodaySGame2] = useState();
    
    const [allUser, setAllUser] = useState();
    const [allRecharge, setAllRecharge] = useState();
    const [allWithdraw, setAllWithdraw] = useState();
    const [allProfit, setAllProfit] = useState();
    const [allSGame, setAllSGame] = useState();
    const [allCGame, setAllCGame] = useState();
    const [allSGame2, setAllSGame2] = useState();
    const [allCGame2, setAllCGame2] = useState();
    const [allRechargeReq, setAllRechargeReq] = useState();
    const [allWithdrawReq, setAllWithdrawReq] = useState();
    const [allUnsolvedGame, setAllUnsolvedGame] = useState();
    const [allUnsolvedGame2, setAllUnsolvedGame2] = useState();
    useEffect(() => {

        let mounted = true;
        if (mounted) {


            if (user.userId == null) {
                window.location.reload(true);
            }


        }
        pageLoad();
        return () => (mounted = false);
    }, []);
    const pageLoad = () => {
        getTodayUser();
        getAllUser();
        getAllRecharge();
        getAllWithdraw();
        getAllProfit();
        getAllCGame();
        getAllSGame();
        getAllCGame2();
        getAllSGame2();
        getTodayRecharge();
        getTodayWithdraw();
        getTodayProfit();
        getTodayCGame();
        getTodaySGame();
        getTodayCGame2();
        getTodaySGame2();
        getAllRechargeReq();
        getAllWithdrawReq();
        getAllUnsolvedGame1();
        getAllUnsolvedGame2();
    }

    const getTodayUser = () => {
        const data = {
            fromDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
            toDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)
        }
        console.log("datadatadata",data)
        axiosInstance.post(`/member/report` , data).then((response) => {
            if(response.data.data.length>0){
                setTodayUser(...response.data.data);
            }
            else{
                setTodayUser({count:0})
            }
        });
        
    }
    const getAllUser = () => {
        
        axiosInstance.post(`/member/report`).then((response) => {
            if(response.data.data.length>0){
                setAllUser(...response.data.data);
            }
            else{
                setAllUser({count:0})
            }
           
        });
    }
    const getTodayRecharge = () => {
        const data = {
            fromDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
            toDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)
        }
        console.log("data",data)
        axiosInstance.post(`/payment/report` , data).then((response) => {
            if(response.data.data.length>0){
                setTodayrecharge(...response.data.data);
            }
            else{
                setTodayrecharge({amount:0})
            }
        });
        
    }
    const getAllRecharge = () => {
        
        axiosInstance.post(`/payment/report`).then((response) => {

            if(response.data.data.length>0){
                setAllRecharge(...response.data.data);
            }
            else{
                setAllRecharge({amount:0})
            }
           
        });
    }
    const getTodayWithdraw = () => {
        const data = {
            fromDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
            toDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)
        }
        axiosInstance.post(`/withdraw/report` , data).then((response) => {
            if(response.data.data.length>0){
                setTodayWithdraw(...response.data.data);
            }
            else{
                setTodayWithdraw({amount:0})
            }
        });
        
    }
    const getAllWithdraw = () => {
        
        axiosInstance.post(`/withdraw/report`).then((response) => {

            if(response.data.data.length>0){
                setAllWithdraw(...response.data.data);
            }
            else{
                setAllWithdraw({amount:0})
            }
           
        });
    }
    const getTodayProfit = () => {
        const data = {
            fromDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
            toDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)
        }
        axiosInstance.post(`/profit/report` , data).then((response) => {
            if(response.data.data.length>0){
                setTodayProfit(...response.data.data);
            }
            else{
                setTodayProfit({amount:0})
            }
        });
        
    }
    const getAllProfit = () => {
        
        axiosInstance.post(`/profit/report`).then((response) => {

            if(response.data.data.length>0){
                setAllProfit(...response.data.data);
            }
            else{
                setAllProfit({amount:0})
            }
           
        });
    }
    const getTodaySGame = () => {
        const data = {
            fromDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
            toDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)
        }
        axiosInstance.post(`/game/report` , data).then((response) => {

            if(response.data.data.length>0){
                setTodaySGame(...response.data.data);
            }
            else{
                setTodaySGame({count:0})
            }
        });
        
    }
    const getAllSGame = () => {
        
        axiosInstance.post(`/game/report`).then((response) => {

            if(response.data.data.length>0){
                setAllSGame(...response.data.data);
            }
            else{
                setAllSGame({count:0})
            }
           
        });
    }
    const getTodayCGame = () => {
        const data = {
            fromDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
            toDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)
        }
        axiosInstance.post(`/game/report2` , data).then((response) => {
            if(response.data.data.length>0){
                setTodayCGame(...response.data.data);
            }
            else{
                setTodayCGame({count:0})
            }
        });
        
    }
    const getAllCGame = () => {
        
        axiosInstance.post(`/game/report2`).then((response) => {

            if(response.data.data.length>0){
                setAllCGame(...response.data.data);
            }
            else{
                setAllCGame({count:0})
            }
           
        });
    }

    const getTodaySGame2 = () => {
        const data = {
            fromDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
            toDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)
        }
        axiosInstance.post(`/game2/report` , data).then((response) => {

            if(response.data.data.length>0){
                setTodaySGame2(...response.data.data);
            }
            else{
                setTodaySGame2({count:0})
            }
        });
        
    }
    const getAllSGame2 = () => {
        
        axiosInstance.post(`/game2/report`).then((response) => {

            if(response.data.data.length>0){
                setAllSGame2(...response.data.data);
            }
            else{
                setAllSGame2({count:0})
            }
           
        });
    }
    const getTodayCGame2 = () => {
        const data = {
            fromDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0),
            toDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59)
        }
        axiosInstance.post(`/game2/report2` , data).then((response) => {
            if(response.data.data.length>0){
                setTodayCGame2(...response.data.data);
            }
            else{
                setTodayCGame2({count:0})
            }
        });
        
    }
    const getAllCGame2 = () => {
        
        axiosInstance.post(`/game2/report2`).then((response) => {

            if(response.data.data.length>0){
                setAllCGame2(...response.data.data);
            }
            else{
                setAllCGame2({count:0})
            }
           
        });
    }
    const getAllRechargeReq = () => {
        
        axiosInstance.post(`/payment/report2`).then((response) => {

            if(response.data.data.length>0){
                setAllRechargeReq(...response.data.data);
            }
            else{
                setAllRechargeReq({count:0})
            }
           
        });
    }
    const getAllWithdrawReq = () => {
        
        axiosInstance.post(`/withdraw/report2`).then((response) => {

            if(response.data.data.length>0){
                setAllWithdrawReq(...response.data.data);
            }
            else{
                setAllWithdrawReq({count:0})
            }
           
        });
    }
    const getAllUnsolvedGame1 = () => {
        
        axiosInstance.post(`/game1f/report`).then((response) => {

            if(response.data.data.length>0){
                setAllUnsolvedGame(...response.data.data);
            }
            else{
                setAllUnsolvedGame({count:0})
            }
           
        });
    }
    const getAllUnsolvedGame2 = () => {
        
        axiosInstance.post(`/game2f/report`).then((response) => {

            if(response.data.data.length>0){
                setAllUnsolvedGame2(...response.data.data);
            }
            else{
                setAllUnsolvedGame2({count:0})
            }
           
        });
    }

    return (

     <div className="rajaji02">
           <div className="container">
            <div className="ramjilalal">
                <img className="bgbackground" src={bg} alt="" />
            </div>
            <h2 className="mb-4  " style={{color:"#rgb(255 9 1)"}}>Dashboard</h2>
            
            <div class="row">
                <div class="col-lg-6 col-xl-3 mb-4">
                    <div class="card bg-primary  text-white h-100">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="me-3">
                                    <div class="text-white-75 small">User Join (Today)/All</div>
                                    {todayUser === undefined && allUser === undefined && <div class="text-lg fw-bold  mybigdata">0/0</div>}
                                    {todayUser !== undefined && allUser !== undefined && <div class="text-lg fw-bold  mybigdata">{todayUser.count}/{allUser.count}</div>}
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar feather-xl text-white-50"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
                            </div>
                        </div>
                        <div class="card-footer d-flex align-items-center justify-content-between small">
                            <a class="text-white stretched-link" href="#/member">View Report</a>
                            <div class="text-white"><svg class="svg-inline--fa fa-angle-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M246.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 41.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg></div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-3 mb-4">
                    <div class="card bg-warning text-white h-100">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="me-3">
                                    <div class="text-white-75 small">Recharge  (Today)/All</div>
                                    {todayRecharge === undefined && allRecharge === undefined &&<div class="text-lg fw-bold  mybigdata">0/0</div>}
                                    {todayRecharge !== undefined && allRecharge !== undefined &&<div class="text-lg fw-bold  mybigdata">{todayRecharge.amount}/{allRecharge.amount}</div>}
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign feather-xl text-white-50"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                            </div>
                        </div>
                        <div class="card-footer d-flex align-items-center justify-content-between small">
                            <a class="text-white stretched-link"  href="#/ARecharge">View Report</a>
                            <div class="text-white"><svg class="svg-inline--fa fa-angle-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M246.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 41.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg> </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-3 mb-4">
                    <div class="card bg-success text-white h-100">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="me-3">
                                    <div class="text-white-75 small">Withdrawal  (Today)/All</div>
                                    {todayWithdraw === undefined && allWithdraw === undefined &&<div class="text-lg fw-bold  mybigdata">0/0</div>}
                                    {todayWithdraw !== undefined && allWithdraw !== undefined &&<div class="text-lg fw-bold  mybigdata">{todayWithdraw.amount}/{allWithdraw.amount}</div>}
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-square feather-xl text-white-50"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg>
                            </div>
                        </div>
                        <div class="card-footer d-flex align-items-center justify-content-between small">
                            <a class="text-white stretched-link" href="#/AWithdraw">View Tasks</a>
                            <div class="text-white"><svg class="svg-inline--fa fa-angle-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M246.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 41.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg></div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-3 mb-4">
                    <div class="card bg-danger text-white h-100">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="me-3">
                                    <div class="text-white-75 small">Profit (Today)/All</div>
                                    {todayProfit === undefined && allProfit === undefined &&<div class="text-lg fw-bold  mybigdata">0/0</div>}
                                    {todayProfit !== undefined && allProfit !== undefined &&<div class="text-lg fw-bold  mybigdata">{todayProfit.amount}/{allProfit.amount}</div>}
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-circle feather-xl text-white-50"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg>
                            </div>
                        </div>
                        <div class="card-footer d-flex align-items-center justify-content-between small">
                            <a class="text-white stretched-link" href="javascript:(avoid)">View Requests</a>
                            <div class="text-white"><svg class="svg-inline--fa fa-angle-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M246.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 41.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg></div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="row">
                {/* <div class="col-xxl-4 col-md-6 mb-4">
              <div class="card 02 info-card sales-card">

                <div class="filter">
                  <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-three-dots"></i></a>
                  <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <li class="dropdown-header text-start">
                      <h6>Filter</h6>
                    </li>

                    <li><a class="dropdown-item" href="#">Today</a></li>
                  
                  </ul>
                </div>

                <div class="card-body">
                  <h5 class="card-title">User Join<span>| Today</span></h5>

                  <div class="d-flex align-items-center">
                    <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i class="bi bi-cart"></i>
                    </div>
                    <div class="">
                      <h4>145</h4>

                    </div>
                  </div>
                </div>

              </div>
    </div> */}




            </div>
            <div class="row">
                <div class="col-lg-6 col-xl-3 mb-4">
                    <div class="card border-primary  text-black h-100">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="me-3">
                                    <div class="text-white-75 small">Unsolved Fraud Game 1</div>
                                    {allUnsolvedGame === undefined &&<div class="text-lg fw-bold  mybigdata">0</div>}
                                    {allUnsolvedGame !== undefined &&<div class="text-lg fw-bold  mybigdata">{allUnsolvedGame.count}</div>}
                                    
                                </div>
                            </div>
                        </div>
                        <div class="card-footer bg-primary d-flex align-items-center justify-content-between small">
                            <a class="text-white stretched-link" href="#/fGame1">View Report</a>
                            <div class="text-black"><svg class="svg-inline--fa fa-angle-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M246.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 41.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg></div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-3 mb-4">
                    <div class="card  border-warning text-black h-100">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="me-3">
                                <div class="text-white-75 small">Unsolved Fraud Game 2</div>
                                    {allUnsolvedGame2 === undefined &&<div class="text-lg fw-bold  mybigdata">0</div>}
                                    {allUnsolvedGame2 !== undefined &&<div class="text-lg fw-bold  mybigdata">{allUnsolvedGame2.count}</div>}
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex align-items-center justify-content-between small bg-warning">
                            <a class="text-white stretched-link" href="#/fGame2">View Report</a>
                            <div class="text-white"><svg class="svg-inline--fa fa-angle-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M246.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 41.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg> </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-3 mb-4">
                    <div class="card border-success  text-black h-100">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="me-3">
                                    <div class="text-black-75 small">Withdrawal Request </div>
                                    {allWithdrawReq === undefined &&<div class="text-lg fw-bold  mybigdata">0</div>}
                                    {allWithdrawReq !== undefined &&<div class="text-lg fw-bold  mybigdata">{allWithdrawReq.count}</div>}
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-square feather-xl text-white-50"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg>
                            </div>
                        </div>
                        <div class="card-footer d-flex align-items-center justify-content-between small bg-success">
                            <a class="text-white stretched-link" href="#/AWithdraw">View Tasks</a>
                            <div class="text-white"><svg class="svg-inline--fa fa-angle-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M246.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 41.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg></div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-3 mb-4">
                    <div class="card border-danger text-black h-100">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="me-3">
                                    <div class="text-white-75 small">Recharge Request</div>
                                    {allRechargeReq === undefined &&<div class="text-lg fw-bold  mybigdata">0</div>}
                                    {allRechargeReq !== undefined &&<div class="text-lg fw-bold  mybigdata">{allRechargeReq.count}</div>}
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-message-circle feather-xl text-white-50"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg>
                            </div>
                        </div>
                        <div class="card-footer d-flex align-items-center justify-content-between small bg-danger">
                            <a class="text-white stretched-link" href="#/ARecharge">View Requests</a>
                            <div class="text-white"><svg class="svg-inline--fa fa-angle-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M246.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 41.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-6 col-xl-3 mb-4">
                    <div class="card bg-primary  text-white h-100">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="me-3">
                                    <div class="text-white-75 small">Game 1 (Today)/All </div>
                                    {todaySGame === undefined && allSGame === undefined &&<div class="text-lg fw-bold  mybigdata">0/0</div>}
                                    {todaySGame !== undefined && allSGame !== undefined &&<div class="text-lg fw-bold  mybigdata">{todaySGame.count}/{allSGame.count}</div>}
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex align-items-center justify-content-between small">
                            <a class="text-white stretched-link" href="#/sGame1">View Report</a>
                            <div class="text-white"><svg class="svg-inline--fa fa-angle-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M246.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 41.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg></div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-3 mb-4">
                    <div class="card bg-warning text-white h-100">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="me-3">
                                <div class="text-white-75 small">Game 2 (Today)/All</div>
                                    {todaySGame2 === undefined && allSGame2 === undefined &&<div class="text-lg fw-bold  mybigdata">0/0</div>}
                                    {todaySGame2 !== undefined && allSGame2 !== undefined &&<div class="text-lg fw-bold  mybigdata">{todaySGame2.count}/{allSGame2.count}</div>}
                                    
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex align-items-center justify-content-between small">
                            <a class="text-white stretched-link" href="#/sGame2">View Report</a>
                            <div class="text-white"><svg class="svg-inline--fa fa-angle-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M246.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 41.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg> </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-3 mb-4">
                    <div class="card bg-success text-white h-100">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="me-3">
                                <div class="text-white-75 small">Cancel Game 1 (Today)/All</div>
                                    {todayCGame === undefined && allCGame === undefined &&<div class="text-lg fw-bold  mybigdata">0/0</div>}
                                    {todayCGame !== undefined && allCGame !== undefined &&<div class="text-lg fw-bold  mybigdata">{todayCGame.count}/{allCGame.count}</div>}
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex align-items-center justify-content-between small">
                            <a class="text-white stretched-link" href="#/cGame1">View Tasks</a>
                            <div class="text-white"><svg class="svg-inline--fa fa-angle-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M246.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 41.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg></div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-3 mb-4">
                    <div class="card bg-danger text-white h-100">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="me-3">
                                <div class="text-white-75 small">Cancel Game 2 (Today)/All</div>
                                    {todayCGame2 === undefined && allCGame2 === undefined &&<div class="text-lg fw-bold  mybigdata">0/0</div>}
                                    {todayCGame2 !== undefined && allCGame2 !== undefined &&<div class="text-lg fw-bold  mybigdata">{todayCGame2.count}/{allCGame2.count}</div>}
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex align-items-center justify-content-between small">
                            <a class="text-white stretched-link" href="#/cGame2">View Requests</a>
                            <div class="text-white"><svg class="svg-inline--fa fa-angle-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M246.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 41.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg></div>
                        </div>
                    </div>
                </div>
            </div>



            {/* <div class="row">
                <div class="col-lg-6 col-xl-3 mb-4">
                    <div class="card border-primary  text-black h-100">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="me-3">
                                <div class="text-black-75 small">..........</div>
                                    <div class="text-lg fw-bold  mybigdata">.........</div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer bg-primary d-flex align-items-center justify-content-between small">
                            <a class="text-white stretched-link" href="#!">View Report</a>
                            <div class="text-black"><svg class="svg-inline--fa fa-angle-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M246.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 41.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg></div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-3 mb-4">
                    <div class="card  border-warning text-black h-100">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="me-3">
                                    <div class="text-white-75 small">Cancel Game 2 (Today)/All</div>
                                    <div class="text-black-75 small">..........</div>
                                    <div class="text-lg fw-bold  mybigdata">.........</div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex align-items-center justify-content-between small bg-warning">
                            <a class="text-white stretched-link" href="#!">View Report</a>
                            <div class="text-white"><svg class="svg-inline--fa fa-angle-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M246.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 41.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg> </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-3 mb-4">
                    <div class="card border-success  text-black h-100">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="me-3">
                                    <div class="text-black-75 small">..........</div>
                                    <div class="text-lg fw-bold  mybigdata">.........</div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex align-items-center justify-content-between small bg-success">
                            <a class="text-white stretched-link" href="#!">View Tasks</a>
                            <div class="text-white"><svg class="svg-inline--fa fa-angle-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M246.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 41.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg></div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-xl-3 mb-4">
                    <div class="card border-danger text-black h-100">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="me-3">
                                    <div class="text-white-75 small">.....</div>
                                    <div class="text-lg fw-bold  mybigdata">.........</div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-flex align-items-center justify-content-between small bg-danger">
                            <a class="text-white stretched-link" href="#!">View Requests</a>
                            <div class="text-white"><svg class="svg-inline--fa fa-angle-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg=""><path fill="currentColor" d="M246.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 41.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg></div>
                        </div>
                    </div>
                </div>
            </div> */}

        </div>
     </div>
    );
};

export default HomeScreen;